// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * @author Dakota Soares
 * @version 1.1
 * @description Meatball Menu Component CSS
 */

.meatball-menu-container {
    position: relative;
  }
  
  .meatball-menu {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
  
  .meatball-menu-options {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 100px;
  }
  
  .meatball-menu-options.expand-upwards {
    bottom: 100%;
    top: auto;
  }
  
  .menu-option {
    display: block;
    width: 100%;
    background-color: #f4f4f4;
    border: none;
    padding: 10px;
    cursor: pointer;
    text-align: left;
  }
  
  .menu-option:hover {
    background-color: #ddd;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/MeatballMenu/MeatballMenu.css"],"names":[],"mappings":"AAAA;;;;EAIE;;AAEF;IACI,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,uBAAuB;IACvB,sBAAsB;IACtB,0CAA0C;IAC1C,aAAa;IACb,YAAY;EACd;;EAEA;IACE,YAAY;IACZ,SAAS;EACX;;EAEA;IACE,cAAc;IACd,WAAW;IACX,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,sBAAsB;EACxB","sourcesContent":["/**\n * @author Dakota Soares\n * @version 1.1\n * @description Meatball Menu Component CSS\n */\n\n.meatball-menu-container {\n    position: relative;\n  }\n  \n  .meatball-menu {\n    background: none;\n    border: none;\n    font-size: 18px;\n    cursor: pointer;\n  }\n  \n  .meatball-menu-options {\n    position: absolute;\n    top: 100%;\n    left: 0;\n    background-color: white;\n    border: 1px solid #ddd;\n    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);\n    z-index: 1000;\n    width: 100px;\n  }\n  \n  .meatball-menu-options.expand-upwards {\n    bottom: 100%;\n    top: auto;\n  }\n  \n  .menu-option {\n    display: block;\n    width: 100%;\n    background-color: #f4f4f4;\n    border: none;\n    padding: 10px;\n    cursor: pointer;\n    text-align: left;\n  }\n  \n  .menu-option:hover {\n    background-color: #ddd;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
