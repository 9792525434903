// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * @author Dakota Soares
 * @version 1.1
 * @description Footer Component CSS
 */

.footer {
  background-color: #282c34;
  padding: 10px;
  color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1001;
  display: flex;
  justify-content: space-between;
}

.footer-content {
  justify-content: space-between;
  width: 100%;
}

.footer-text {
  text-align: right;
  padding-right: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;;;;EAIE;;AAEF;EACE,yBAAyB;EACzB,aAAa;EACb,YAAY;EACZ,eAAe;EACf,SAAS;EACT,WAAW;EACX,aAAa;EACb,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,8BAA8B;EAC9B,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB","sourcesContent":["/**\n * @author Dakota Soares\n * @version 1.1\n * @description Footer Component CSS\n */\n\n.footer {\n  background-color: #282c34;\n  padding: 10px;\n  color: white;\n  position: fixed;\n  bottom: 0;\n  width: 100%;\n  z-index: 1001;\n  display: flex;\n  justify-content: space-between;\n}\n\n.footer-content {\n  justify-content: space-between;\n  width: 100%;\n}\n\n.footer-text {\n  text-align: right;\n  padding-right: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
