// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * @author Dakota Soares
 * @version 1.1
 * @description Popup Component CSS
 */

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.popup {
  background-color: white;
  padding: 20px;
  border: 1px solid #ddd;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto; 
  position: relative;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.popup h2 {
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/Popup/Popup.css"],"names":[],"mappings":"AAAA;;;;EAIE;;AAEF;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,UAAU;EACV,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;EAClB,0CAA0C;AAC5C;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,eAAe;AACjB","sourcesContent":["/**\n * @author Dakota Soares\n * @version 1.1\n * @description Popup Component CSS\n */\n\n.popup-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 2000;\n}\n\n.popup {\n  background-color: white;\n  padding: 20px;\n  border: 1px solid #ddd;\n  width: 90%;\n  max-width: 500px;\n  max-height: 90vh;\n  overflow-y: auto; \n  position: relative;\n  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n.popup h2 {\n  margin-top: 0;\n  margin-bottom: 20px;\n  text-align: center;\n}\n\n.popup-close {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  background: none;\n  border: none;\n  font-size: 20px;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
