// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * @author Dakota Soares
 * @version 1.1
 * @description Search Bar with Filter Component CSS
 */

.search-bar-container {
    display: flex;
    align-items: center;
  }
  
  .search-input {
    width: 400px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px 0 0 4px;
  }
  
  .search-filter-dropdown {
    padding: 9px;
    border: 1px solid #ddd;
    border-left: none;
    border-radius: 0 4px 4px 0;
    background-color: #fff;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/SearchBarWithFilter/SearchBarWithFilter.css"],"names":[],"mappings":"AAAA;;;;EAIE;;AAEF;IACI,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,0BAA0B;EAC5B;;EAEA;IACE,YAAY;IACZ,sBAAsB;IACtB,iBAAiB;IACjB,0BAA0B;IAC1B,sBAAsB;EACxB","sourcesContent":["/**\n * @author Dakota Soares\n * @version 1.1\n * @description Search Bar with Filter Component CSS\n */\n\n.search-bar-container {\n    display: flex;\n    align-items: center;\n  }\n  \n  .search-input {\n    width: 400px;\n    padding: 10px;\n    border: 1px solid #ddd;\n    border-radius: 4px 0 0 4px;\n  }\n  \n  .search-filter-dropdown {\n    padding: 9px;\n    border: 1px solid #ddd;\n    border-left: none;\n    border-radius: 0 4px 4px 0;\n    background-color: #fff;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
