// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * @author Dakota Soares
 * @version 1.1
 * @description Popup Component CSS
 */

.message-popup {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 3000;
    opacity: 1;
    transition: opacity 0.5s ease-out;
    animation: fadeOut 5s forwards;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/MessagePopup/MessagePopup.css"],"names":[],"mappings":"AAAA;;;;EAIE;;AAEF;IACI,eAAe;IACf,SAAS;IACT,WAAW;IACX,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,0CAA0C;IAC1C,aAAa;IACb,UAAU;IACV,iCAAiC;IACjC,8BAA8B;AAClC;;AAEA;IACI;QACI,UAAU;IACd;IACA;QACI,UAAU;IACd;IACA;QACI,UAAU;IACd;AACJ","sourcesContent":["/**\n * @author Dakota Soares\n * @version 1.1\n * @description Popup Component CSS\n */\n\n.message-popup {\n    position: fixed;\n    top: 20px;\n    right: 20px;\n    background-color: #4CAF50;\n    color: white;\n    padding: 10px 20px;\n    border-radius: 4px;\n    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);\n    z-index: 3000;\n    opacity: 1;\n    transition: opacity 0.5s ease-out;\n    animation: fadeOut 5s forwards;\n}\n\n@keyframes fadeOut {\n    0% {\n        opacity: 1;\n    }\n    90% {\n        opacity: 1;\n    }\n    100% {\n        opacity: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
