// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * @author Dakota Soares
 * @version 1.1
 * @description Custom Dropdown Component CSS
 */

.custom-dropdown {
    position: relative;
    width: 100%;
  }
  
  .dropdown-toggle {
    width: 100%;
    padding: 8px;
    text-align: left;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    box-sizing: border-box;
  }
  
  .dropdown-toggle .arrow {
    float: right;
  }
  
  .dropdown-options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background-color: white;
    border: 1px solid #ddd;
    border-top: none;
    z-index: 1000;
    box-sizing: border-box;
  }
  
  .dropdown-option {
    padding: 8px;
    cursor: pointer;
  }
  
  .dropdown-option:hover {
    background-color: #f4f4f4;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/CustomDropdown/CustomDropdown.css"],"names":[],"mappings":"AAAA;;;;EAIE;;AAEF;IACI,kBAAkB;IAClB,WAAW;EACb;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,uBAAuB;IACvB,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,sBAAsB;EACxB;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,WAAW;IACX,iBAAiB;IACjB,gBAAgB;IAChB,uBAAuB;IACvB,sBAAsB;IACtB,gBAAgB;IAChB,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/**\n * @author Dakota Soares\n * @version 1.1\n * @description Custom Dropdown Component CSS\n */\n\n.custom-dropdown {\n    position: relative;\n    width: 100%;\n  }\n  \n  .dropdown-toggle {\n    width: 100%;\n    padding: 8px;\n    text-align: left;\n    background-color: white;\n    border: 1px solid #ddd;\n    border-radius: 4px;\n    cursor: pointer;\n    box-sizing: border-box;\n  }\n  \n  .dropdown-toggle .arrow {\n    float: right;\n  }\n  \n  .dropdown-options {\n    position: absolute;\n    top: 100%;\n    left: 0;\n    width: 100%;\n    max-height: 200px;\n    overflow-y: auto;\n    background-color: white;\n    border: 1px solid #ddd;\n    border-top: none;\n    z-index: 1000;\n    box-sizing: border-box;\n  }\n  \n  .dropdown-option {\n    padding: 8px;\n    cursor: pointer;\n  }\n  \n  .dropdown-option:hover {\n    background-color: #f4f4f4;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
