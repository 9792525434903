// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * @author Dakota Soares
 * @version 1.1
 * @description Layout Component CSS
 */

.layout {
  position: absolute;
  top: 50px;    
  bottom: 50px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
}

.layout-content {
  flex-grow: 1;
  padding: 20px;
  margin-left: 150px;
  transition: margin-left 0.3s ease;
  overflow-y: auto;  
}

.layout-collapsed .layout-content {
  margin-left: 40px;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  z-index: 1000;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  z-index: 1000;
}
`, "",{"version":3,"sources":["webpack://./src/components/Layout/Layout.css"],"names":[],"mappings":"AAAA;;;;EAIE;;AAEF;EACE,kBAAkB;EAClB,SAAS;EACT,YAAY;EACZ,OAAO;EACP,QAAQ;EACR,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,iCAAiC;EACjC,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,eAAe;EACf,SAAS;EACT,OAAO;EACP,QAAQ;EACR,YAAY;EACZ,aAAa;AACf","sourcesContent":["/**\n * @author Dakota Soares\n * @version 1.1\n * @description Layout Component CSS\n */\n\n.layout {\n  position: absolute;\n  top: 50px;    \n  bottom: 50px;\n  left: 0;\n  right: 0;\n  display: flex;\n  flex-direction: row;\n}\n\n.layout-content {\n  flex-grow: 1;\n  padding: 20px;\n  margin-left: 150px;\n  transition: margin-left 0.3s ease;\n  overflow-y: auto;  \n}\n\n.layout-collapsed .layout-content {\n  margin-left: 40px;\n}\n\n.header {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  height: 50px;\n  z-index: 1000;\n}\n\n.footer {\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  height: 50px;\n  z-index: 1000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
