// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * @author Dakota Soares
 * @version 1.1
 * @description Landing Page CSS
 */

.landing-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 120px);
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/landingPage/landingPage.css"],"names":[],"mappings":"AAAA;;;;EAIE;;AAEF;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,2BAA2B;EAC3B,kBAAkB;AACpB","sourcesContent":["/**\n * @author Dakota Soares\n * @version 1.1\n * @description Landing Page CSS\n */\n\n.landing-page {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: calc(100vh - 120px);\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
